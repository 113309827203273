/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
// import WOW from 'wow.js'; // Import WOW.js for animations
import "./animate.css"; // Import animate.css for pre-defined animations
import Section from "./SectionLeft";
import Section02 from "./SectionRight";
import FooterForOurServices from "./FooterForOurServices";
// import { Title } from '@mui/icons-material';
// import { p, scroller } from "react-scroll";
// import { useLocation } from "react-router-dom";

const Eprocurement = () => {
  // const location = useLocation();

  // useEffect(() => {
  //   const section = location.hash.replace("#", "");
  //   if (section) {
  //     scroller.scrollTo(section, {
  //       duration: 500,
  //       delay: 0,
  //       smooth: "easeInOutQuart",
  //     });
  //   }
  // }, [location]);
  const evenData = [
    {
      Id: "1",
      Title1: "E2E Procurement",
      // Title2: "Source-to-Contract and Procure-to-Pay",
      // text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seiam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
      point1title:"Procure to Pay",
      points1:[
            {
              point:"Tail spend Management (Supplier aggregation)",
            },
            {
              point:"80% of suppliers account for only 20% of spend",
            },
            {
              point:"Consolidates the fragmented Supplier base which makes the customer to deal with only one entity for all its procurement",
            },
            {
              point:"One-stop shop for all MRO needs vs dealing with fragmented vendor base",
            },
            {
              point:"Standard Terms & Conditions for more than 50% of your Transactions",
            },
            {
              point:"Price Standardization/inflation savings",
            },
            {
              point:"Optimized PR-PO Lead Time (Ordering at zero lead time)",
            },
      ],
      image: "./assets/images/oursource/e2eprocurement.png",
      // image: "./assets/flowimage/PARTNER.PNG",
      path: "/features",
    },
    {
      Id: "2",
      Title1: " Chain of Hardware Stores",
      // Title2: "Sourcing, Contract Management...",
      point1title:"Management of Hardware Stores & Tools",
      points1:[
        {
          point:"In India hardware stores are mostly small businesses, with no major store chains that carry a large selection of products.",
        },
        {
          point:"Byizzy, we want to Integrate and create chain of Hardware stores (which are losing their business in the market because of huge competition ) under one roof of our brand, expertise and technology (Major Target to reduce their dead inventory, improve margins & Business volume)",
        },
        {
          point:"Customers to shop whenever, wherever, and however they want. Browse products online, check availability in nearby stores, and make purchases for delivery or in-store pickup",
        },
        {
          point:"Support to develop more B2B customers",
        },
        {
          point:"Currently local stores are not much competitive as compared to the aggregators due to insufficient resources, ended in dead inventory, inefficient sourcing & operation etc.",
        },
        {
          point:"Lowest prices",
        },
        {
          point:"Widest range",
        },
        {
          point:"Best experience",
        },
  ],
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seiam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
      image: "./assets/images/oursource/chainHardware.png",
      path: "/features",
    },
    {
      Id: "2",
      Title1: "Software as a service (SaaS)",
      // Title2: "Sourcing, Contract Management...",
      point1title:"Supplier Portal / Customer Portal",
      points1:[
        {
          point:"Integrating Hardware stores (which are losing their business in the market because of huge competition ) under one roof of our brand, expertise and technology (Major Target to reduce their dead inventory, improve margins & Business volume)",
        },
        {
          point:"Customers to shop whenever, wherever, and however they want. Browse products online, check availability in nearby stores, and make purchases for delivery or in-store pickup",
        },
        {
          point:"Support to develop more B2B customers",
        },
        {
          point:"Currently local stores are not much competitive as compared to the aggregators due to insufficient resources, ended in dead inventory, inefficient sourcing & operation etc.",
        },
      
  ],
      text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seiam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
      image: "./assets/images/oursource/sas.png",
      path: "/features",
    },
  
  ];
  return (
    <>
      <div className="container products">

        <p name="vendoremanagement">
          <div className="common-wdt-top">
            <h1 className="product-title text-center my-4" style={{color:"#007aff"}}>Our services</h1>
            <Section evenData={evenData[0]} />
          </div>
        </p>
        <FooterForOurServices/>
      </div>

    </>
  );
};

export default Eprocurement;
