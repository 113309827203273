
// import AboutUs1 from "./AboutUs1"
import AboutUs2 from "./AboutUs2"
import AboutUs3 from "./AboutUs3"
// import AboutUs4 from "./AboutUs4"
// import AboutUs5 from "./AboutUs5"
// import AboutUs6 from "./AboutUs6"

const AboutUs=()=>{
    return(
        <>
        {/* <AboutUs1/> */}
        <div className="main_content">
        <AboutUs2/>
        <AboutUs3/>
        {/* <AboutUs4/> */}
        {/* <AboutUs6/> */}
        {/* <AboutUs5/> */}
        </div>
        </>
    )
}
export default AboutUs