// import AboutUs from '../AboutUs/AboutUs';
import { useEffect, useRef } from "react";

const Section4 = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          video.play();
        } else {
          video.pause();
        }
      });
    });

    if (video) {
      observer.observe(video);
    }

    return () => {
      if (video) {
        observer.unobserve(video);
      }
    };
  }, []);
  return (
    <>
      {/* <div className="section  section4 "> */}
      <div className="section  small_pt pb_20">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <video ref={videoRef} width="600" muted loop>
                {/* <source src="./assets/videos/whoweare.mp4" type="video/mp4" /> */}
                <source src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fvideo%2FByizzy_video.mp4?alt=media&token=fad0b527-8429-409e-9dee-4eb4b0acd5c5" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Section4;
