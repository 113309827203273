const AboutUs3 = () => {
  return (
    <>
   <div className="section  pb_70">
	<div className="container">
    	<div className="row justify-content-center">
        	<div className="col-lg-6 col-md-8">
            	<div className="heading_s1 text-center">
                	<h2>Why Byizzy?</h2>
                </div>
                <p className="text-center leads">Sourcing Success, Delivering Values</p>
            </div>
        </div>
        <div className="row justify-content-center">
        	<div className="col-lg-3 col-sm-6">
            	<div className="icon_box icon_box_style4 box_shadow1">
                	<div className="icon">
                        <i className="ti-settings"></i>
                    </div>
                    <div className="icon_box_content">
                    	<h5>Efficient sourcing</h5>
                        <p>There are many variations of have</p>
                    </div>
                </div>
            </div>
        	<div className="col-lg-3 col-sm-6">
            	<div className="icon_box icon_box_style4 box_shadow1">
                	<div className="icon">

                        <i className="ti-tag"></i>
                    </div>
                    <div className="icon_box_content">
                    	<h5>Competitive pricing</h5>
                        <p>There are many variations of have</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6">
            	<div className="icon_box icon_box_style4 box_shadow1">
                	<div className="icon">
                    <i className="fas fa-boxes"></i>
                    </div>
                    <div className="icon_box_content">
                    	<h5>Multiple SKU’s from single supplier</h5>
                        <p>There are many variations of have</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6">
            	<div className="icon_box icon_box_style4 box_shadow1">
                	<div className="icon">
                    <i className="ti-credit-card"></i>
                    </div>
                    <div className="icon_box_content">
                    	<h5>Access to Credit</h5>
                        <p>There are many variations of have</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6">
            	<div className="icon_box icon_box_style4 box_shadow1">
                	<div className="icon">
                    <i className="fas fa-clock"></i>
                    </div>
                    <div className="icon_box_content">
                    	<h5>On-time Delivery</h5>
                        <p>There are many variations of have</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6">
            	<div className="icon_box icon_box_style4 box_shadow1">
                	<div className="icon">
                    <i className="fas fa-check-circle"></i>
                    </div>
                    <div className="icon_box_content">
                    	<h5>Quality Assurance</h5>
                        <p>There are many variations of have</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6">
            	<div className="icon_box icon_box_style4 box_shadow1">
                	<div className="icon">
                    <i className="fas fa-laptop-code"></i>

                    </div>
                    <div className="icon_box_content">
                    	<h5>Technical Knowledge & expertise</h5>
                        <p>There are many variations of have</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6">
            	<div className="icon_box icon_box_style4 box_shadow1">
                	<div className="icon">
                    <i className="fas fa-hands-helping"></i>
                    </div>
                    <div className="icon_box_content">
                    	<h5>Social Obligations</h5>
                        <p>There are many variations of have</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6">
            	<div className="icon_box icon_box_style4 box_shadow1">
                	<div className="icon">
                    <i className="fas fa-hand-holding-heart"></i>
                    </div>
                    <div className="icon_box_content">
                    	<h5>ESG compliance</h5>
                        <p>There are many variations of have</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    </>
  );
};
export default AboutUs3;
