import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import AboutUs from "./Pages/AboutUs/AboutUs";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Faq from "./Pages/Faq/Faq";
import Login from "./Pages/Login/Login";
import Register from "./Pages/Register/Register";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
import GetQuote from "./Pages/Get Quote/GetQuote";
import ShopByCategoriProducts from "./Pages/ShopByCategoriProducts/ShopByCategoriProducts";
import {shopBycategori} from "./config/MenueData";
import { useEffect, useState } from "react";
import ShopByCategoriAllProducts from "./Pages/ShopByCategoriAllProducts/ShopByCategoriAllProducts";
import Eprocurement from "./Pages/OurServices/Eprocurement";
import Esourcing from "./Pages/OurServices/Esourcing";
import Paas from "./Pages/OurServices/Paas";
import ChainofHardware from "./Pages/OurServices/ChainofHardware";
import Saas from "./Pages/OurServices/Saas";


const  Router = () => {
    const [setShopByCategoriProductsdata,getShopByCategoriProductsdata]=useState(shopBycategori)
    // console.log('router setShopByCategoriProductsdata',setShopByCategoriProductsdata)
    useEffect(()=>{
        getShopByCategoriProductsdata(shopBycategori)
    },[])
    return(
        <>
        <Routes>
       
        <Route  path='/' element={<Home/>}></Route>
        <Route  path='aboutus' element={<AboutUs/>}></Route>
        <Route  path='contactus' element={<ContactUs/>}></Route>
        <Route  path='faq' element={<Faq/>}></Route>
        <Route  path='login' element={<Login/>}></Route>
        <Route  path='register' element={<Register/>}></Route>
        <Route  path='TermsAndConditions' element={<TermsAndConditions/>}></Route>
        <Route  path='getquote' element={<GetQuote/>}></Route>
        {/* shopBycategoriproducts */}
        {setShopByCategoriProductsdata.map((items,i)=>(
            <Route key={i} path={items.path} element={<ShopByCategoriProducts ShopByCategoriProductsdata={items}/>}></Route>
        ))}
        <Route  path="/all" element={<ShopByCategoriAllProducts ShopByCategoriProductsdata={setShopByCategoriProductsdata}/>}></Route>
        <Route  path="/eprocurement" element={<Eprocurement/>}></Route>
        <Route  path="/pass" element={<Paas/>}></Route>
        <Route  path="/esourcing" element={<Esourcing/>}></Route>
        <Route  path="/chainofhardware" element={<ChainofHardware/>}></Route>
        <Route  path="/saas" element={<Saas/>}></Route>
        </Routes>
        </>
    )
}
export default Router;