const Logo = () => {
  return (
    <>
      <div className="d-flex justify-content-start align-items-center">
        <img className="ms-5 img-fluid w-25" src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Flogo%2FScreenshot_2.png?alt=media&token=49ffb4ec-15ae-45f3-a3fc-87571f64ebd4" alt="logo" />
        <p className=" px-3 fw-bold py-2 bg-primary text-white fs-4 m-0 p-0 border rounded"> Byyizzy</p>
      </div>
    </>
  );
};
export default Logo;
