const Section1 = () => {
  return (
    <>
      <div className="banner_section full_screen staggered-animation-wrap">
        <div
          id="carouselExampleControls"
          className="carousel slide carousel-fade light_arrow carousel_style2"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div
              className="carousel-item active background_bg overlay_bg_50"
              data-img-src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fscroll%2Fhome2.jpg?alt=media&token=9212458c-b263-4732-a25d-3fb5efafd5c3"
            >
              <div className="banner_slide_content banner_content_inner">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-10">
                      <div className="banner_content text_white text-center">
                        <h2
                          className="staggered-animation"
                          data-animation="fadeInDown"
                          data-animation-delay="0.3s"
                        >
                          Transforming Industrial Supply Chain{" "}
                        </h2>
                        <h5
                          className="staggered-animation"
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          Spares, consumables and Hardware products with
                          Hyper-Local Stores and Advanced Technology
                        </h5>
                        <p
                          className="staggered-animation"
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          Source direct-from-factory || Best prices & trade
                          assurance || OTIF|| Quality Assurance
                        </p>
                        <a
                          className="btn btn-white staggered-animation"
                          href="shop-left-sidebar.html"
                          data-animation="fadeInUp"
                          data-animation-delay="0.5s"
                        >
                          Contact Sales
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="carousel-item background_bg overlay_bg_60"
              data-img-src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fscroll%2Fconstruction_shop_03.jpg?alt=media&token=dda6220d-bd32-4aac-8756-ad39e04b3816"
            >
              <div className="banner_slide_content banner_content_inner">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-10">
                      <div className="banner_content text_white text-center">
                        <h2
                          className="staggered-animation"
                          data-animation="fadeInDown"
                          data-animation-delay="0.3s"
                        >
                          India’s Growing Hardware & DIY Store Brand
                        </h2>
                        <h5
                          className="staggered-animation"
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          To Integrate and create chain of Hardware stores under
                          one roof of our brand, expertise and technology
                        </h5>
                        <p
                          className="staggered-animation"
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          Easy credit & EMI Facility to B2C Customers|| Fastest
                          Delivery of Industrial Products || Optimized inventory
                          Model|| Improved margins || More Business volume
                        </p>
                        <a
                          className="btn btn-white staggered-animation"
                          href="shop-left-sidebar.html"
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          Contact Sales
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="carousel-item background_bg overlay_bg_50"
              data-img-src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fscroll%2Fhome1.jpg?alt=media&token=d218f0c4-5f60-4937-9c17-864680c8921b"
            >
              <div className="banner_slide_content banner_content_inner">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-10">
                      <div className="banner_content text_white text-center">
                        <h2
                          className="staggered-animation"
                          data-animation="fadeInDown"
                          data-animation-delay="0.3s"
                        >
                          Procurement as a service (PaaS)
                        </h2>
                        <h5
                          className="staggered-animation"
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          {" "}
                          Efficient & sustainable way of procurement
                        </h5>
                        <p
                          className="staggered-animation"
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          {" "}
                          Flat 1.5% Charges|| Overall procurement cost
                          optimization|| On Demand Service|| Leverage Global
                          Expertise || Multiple Market places
                        </p>
                        <a
                          className="btn btn-white staggered-animation"
                          href="shop-left-sidebar.html"
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          Contact Sales
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="carousel-item background_bg overlay_bg_60"
              data-img-src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fscroll%2Fhome3.jpg?alt=media&token=c73df4ec-957e-49e0-a475-a704ed970e60"
            >
              <div className="banner_slide_content banner_content_inner">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-10">
                      <div className="banner_content text_white text-center">
                        <h2
                          className="staggered-animation"
                          data-animation="fadeInDown"
                          data-animation-delay="0.3s"
                        >
                          Best & Cost-effective Contract Lifecycle Management
                        </h2>
                        <h5
                          className="staggered-animation"
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          P2P/E-Sourcing/Auction Platforms for your Business
                        </h5>
                        <p
                          className="staggered-animation"
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          Optimize Your Ordering Costs || Entire Procurement
                          Visibility || Flexible, Customizable & Easy-To-Use||
                          Fast & Efficient Platform || Simplify & Streamline
                          Your S2P Process
                        </p>
                        <a
                          className="btn btn-white staggered-animation"
                          href="shop-left-sidebar.html"
                          data-animation="fadeInUp"
                          data-animation-delay="0.4s"
                        >
                          Contact Sales
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

       

          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleControls"
            role="button"
            data-bs-slide="prev"
          >
            <i className="ion-chevron-left"></i>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleControls"
            role="button"
            data-bs-slide="next"
          >
            <i className="ion-chevron-right"></i>
          </a>
        </div>
      </div>
    </>
  );
};
export default Section1;
