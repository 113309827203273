import { useEffect, useRef } from "react";

const Section4 = () => {
  const videoRef = useRef(null);
  const isPlaying = useRef(false); // To track play/pause state

  useEffect(() => {
    const video = videoRef.current;

    const handlePlay = () => {
      if (!isPlaying.current) {
        video.play();
        isPlaying.current = true;
      }
    };

    const handlePause = () => {
      if (isPlaying.current) {
        video.pause();
        isPlaying.current = false;
      }
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          requestAnimationFrame(() => handlePlay());
        } else {
          requestAnimationFrame(() => handlePause());
        }
      });
    });

    if (video) {
      observer.observe(video);
    }

    return () => {
      if (video) {
        observer.unobserve(video);
      }
    };
  }, []);

  return (
    <div className="section p-0 m-0">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12 ">
            <video ref={videoRef} width="600" muted loop >
              <source
                src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fvideo%2Fbyizze_video2.mp4?alt=media&token=7914f55c-4e4d-4274-963b-ad8b543d1af0"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
