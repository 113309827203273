const AboutUs2 = () => {
  return (
    <>
    <div className="section bg_light_blue2">
	<div className="container">
    	<div className="row align-items-center">
        	{/* <div className="col-lg-6">
            	<div className="about_img scene mb-4 mb-lg-0">
                    <img src="assets/images/about_img.jpg" alt="about_img"/>
                </div>
            </div> */}
            <div className="col-lg-12">
                <div className="heading_s1">
                    <h2 className="text-center">Who We are</h2>
                </div>
                <p>To revolutionize the fragmented Industrial goods, Spares, consumables and Hardware products supply chain
                philosophy through Hyper local Stores, E-Commerce platform with proven algorithms, AI & Machine learning</p>
                <p>
                Integrating Hardware stores (which are losing their business in the market because of huge competition ) under
                one roof of our brand, expertise and technology  
                </p>
                <p>
                Own and Franchise Hardware chain model-Integrating shops in the online marketplace for seamless shopping
experience (both online & in-store shopping) and to optimize Hardware stores operation + B2B process
optimization through best-in class strategies with hyper local stores (JIT /VMI approach) and ensuring local
social obligations
                </p>
                <p>
                Best in-class E2E ordering service along with free E-sourcing tools provider
                </p>
                
                {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. consequuntur quibusdam enim expedita sed nesciunt incidunt accusamus adipisci officia libero laboriosam!</p>
                <p>Proin gravida nibh vel velit auctor aliquet. nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vultate cursus a sit amet mauris. Duis sed odio sit amet nibh vultate cursus a sit amet mauris.</p> */}
            </div>
        </div>
    </div>
</div>
    </>
  );
};
export default AboutUs2;
