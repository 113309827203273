const brandLogo = [
  {
    id: "1",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture1.png?alt=media&token=780a5756-4d97-4974-86f7-4e2bdd99a65b",
  },
  {
    id: "2",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture10.png?alt=media&token=a9af8998-c304-42bb-97a2-c5f32d965521",
  },
  {
    id: "3",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture11.png?alt=media&token=e2089f3d-3bf3-48f2-811b-865c93d749af",
  },
  {
    id: "4",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture12.png?alt=media&token=164c7f84-e6aa-47e4-91f4-7e2e049afcf1",
  },
  {
    id: "5",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture13.png?alt=media&token=48d363dd-5667-46b9-875f-cc0d8e8af994",
  },
  {
    id: "6",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture14.png?alt=media&token=c992a8f6-6324-42bc-82d4-ac0ea563eb7f",
  },
  {
    id: "7",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture15.png?alt=media&token=5fa9fb59-2416-4296-aa8d-b3b668b2081e",
  },
  {
    id: "8",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture16.png?alt=media&token=94dba97a-de1c-4d0c-8027-95843684cdb1",
  },
  {
    id: "9",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture17.png?alt=media&token=46ed5983-12e4-4c08-9766-4f838136d0cf",
  },
  {
    id: "10",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture18.png?alt=media&token=e0e8892f-94fb-4986-b91d-f52b497c4403",
  },
  {
    id: "11",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture19.png?alt=media&token=aa0d385b-cf9a-4f4e-a2fe-a6d49dc3bd48",
  },
  {
    id: "12",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture2.png?alt=media&token=1a18acd4-e289-434b-8469-af7068076b06",
  },
  {
    id: "13",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture20.png?alt=media&token=3bdb088b-80d3-4c37-b700-122040013c32",
  },
  {
    id: "14",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture21.png?alt=media&token=1a290292-b058-4a28-be38-6635047e0b48",
  },
  {
    id: "15",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture22.png?alt=media&token=107cc498-2395-41db-a299-f9bcceb38eac",
  },
  {
    id: "16",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture23.png?alt=media&token=9f6ad94b-aa6a-41d7-ae6a-95b891ee1224",
  },
  {
    id: "17",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture24.png?alt=media&token=7b2a2cd7-6945-489c-bf1b-1e64a91eb074",
  },
  {
    id: "18",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture25.png?alt=media&token=407f2a71-62ae-4b83-bb3d-8a398680c58c",
  },
  {
    id: "19",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture26.png?alt=media&token=740cac29-32ed-4da3-b1b4-412be3665828",
  },
  {
    id: "20",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture27.png?alt=media&token=d877d817-818f-48be-8dd9-c29fb5958c10",
  },
  {
    id: "21",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture28.png?alt=media&token=9ecff6cd-5174-482b-9698-a1ab7c50e462",
  },
  {
    id: "22",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture29.png?alt=media&token=d25f2ad5-e0ee-4c7b-b9b5-291aa0fc0b5a",
  },
  {
    id: "23",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture3.png?alt=media&token=538d2f5c-76ad-4e69-88c7-88f0fc53f43e",
  },
  {
    id: "24",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture30.png?alt=media&token=c03faddc-9cb0-4abf-a3ee-c13f120b4356",
  },
  {
    id: "25",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture31.png?alt=media&token=078110dd-41cd-4068-b048-309e17b0317e",
  },
  {
    id: "26",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture32.png?alt=media&token=8473979e-da66-4384-9d80-c48632b104a8",
  },
  {
    id: "27",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture33.png?alt=media&token=e721cbe4-71a8-4bab-be13-5b3bf41103ab",
  },
  {
    id: "28",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture34.png?alt=media&token=ff0ead80-bef5-40dd-9365-62c05c414cc3",
  },
  {
    id: "29",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture35.png?alt=media&token=170b2079-2d73-4c62-aa1e-6553d05dcad6",
  },
  {
    id: "30",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture36.png?alt=media&token=7eb4a6d0-35dc-401c-8dbd-a91808382831",
  },
  {
    id: "31",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture37.png?alt=media&token=1de603a9-8414-41e8-a7f2-0ebed647d217",
  },
  {
    id: "32",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture38.png?alt=media&token=a9ace3c9-e99b-4480-b472-c0f1ed17af37",
  },
  {
    id: "33",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture39.png?alt=media&token=c6d8a05c-cb11-404c-8cdf-0bc98e974720",
  },
  {
    id: "34",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture4.png?alt=media&token=c1f8c3a2-f814-4259-a8e5-239405d8dd03",
  },
  {
    id: "35",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture40.png?alt=media&token=0e8efcf8-7326-471a-b2b6-bba67fd14009",
  },
  {
    id: "36",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture41.png?alt=media&token=a261fa8b-b9af-45f0-8c5e-1fe5fe18b4b4",
  },
  {
    id: "37",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture42.png?alt=media&token=be9ad0c9-5d56-42a1-8d89-1085e44aecb3",
  },
  {
    id: "38",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture43.png?alt=media&token=6401f140-e3e5-43e1-bc69-96e5315d1179",
  },
  {
    id: "39",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture44.png?alt=media&token=f8b49c93-62fc-4932-8e1e-3e4b59355d39",
  },
  {
    id: "40",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture45.png?alt=media&token=eefd245c-bdfe-4560-9d32-ed4706f7fb2c",
  },
  {
    id: "41",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture46.png?alt=media&token=f7a57192-926f-4ec8-b7b9-5e5ee99f37c7",
  },
  {
    id: "42",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture47.png?alt=media&token=afb68af1-4150-47c6-81ab-8a853e4ae541",
  },
  {
    id: "43",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture48.png?alt=media&token=86774c2a-7c56-4bd6-89d5-daf9ec8affd7",
  },
  {
    id: "44",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture49.png?alt=media&token=157ac3d0-361f-468a-8893-7f3495f1d637",
  },
  {
    id: "45",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture5.png?alt=media&token=d49195b8-b25f-4a5d-a62b-4c737f653616",
  },
  {
    id: "46",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture50.png?alt=media&token=77548e34-3365-4425-985b-09bf1cfc01c7",
  },
  {
    id: "47",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture51.png?alt=media&token=6760134b-71d2-4043-bd1f-0a6e45745b59",
  },
  {
    id: "48",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture52.png?alt=media&token=2fcf210e-c070-484c-a7fb-ad7049367a91",
  },
  {
    id: "49",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture53.png?alt=media&token=7629c825-902c-438d-9b83-14ab4b6238a2",
  },
  {
    id: "50",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture54.png?alt=media&token=3dc2c2af-b757-4c15-9509-5d1c70e39928",
  },
  {
    id: "51",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture55.png?alt=media&token=3505112e-ba07-41af-9c28-7010e48c4770",
  },
  {
    id: "52",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture56.png?alt=media&token=3d356774-8251-4798-b693-ea8a058a72d5",
  },
  {
    id: "53",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture6.png?alt=media&token=660a4ef2-69fb-4945-893c-3050d71cc770",
  },
  {
    id: "54",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture7.png?alt=media&token=3fe7d57e-0112-451c-ba26-d2d7f029d143",
  },
  {
    id: "55",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture8.png?alt=media&token=61ac32ae-6d7e-43ca-a236-e54dc3b80905",
  },
  {
    id: "56",
    logo: "https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2Fourbrands%2FPicture8.png?alt=media&token=61ac32ae-6d7e-43ca-a236-e54dc3b80905",
  },
];
const Section6 = () => {
  return (
    <>
      <div className="section small_pt m-0 pt-5 pb-0">
        <div className="container">
          <div className="row">
            <div className="heading_s1 text-center col-12">
              <h2>Our Brands</h2>
            </div>
            <div className="col-12">
              <div
                className="client_logo carousel_slider owl-carousel owl-theme"
                data-dots="false"
                data-margin="30"
                data-loop="true"
                data-autoplay="true"
                data-responsive='{"0":{"items": "2"}, "480":{"items": "3"}, "767":{"items": "4"}, "991":{"items": "5"}}'
              >
                {brandLogo.map((item) => (
                  <>
                    <div className="item" key={item.id}>
                      <div className="cl_logo">
                        <img src={item.logo} alt="cl_logo" />
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Section6;
