import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PopupModal from "../../Components/popupmodel/PopupModal";
// import ShopByCategoriSingleProduct from "./ShopByCategoriSingleProduct";
// import { Link } from "react-router-dom";
const categories = [
  // Agriculture Garden & Landscaping
  "Harvester",
  "Brush Cutter",
  "Sprayers",
  "Grain Processing Machine",
  "Chaff Cutter",
  // Abrasives, Adhesives & Sealants
  "Abrasives",
  "Adhesives",
  // Automotive Supplies & Lubricants
  "Lubricants",
  "Engine Oils",
  "Brake Fluids",
  "Coolants",
  // Bearings & Power Transmission
  "Bearings",
  "Belts",
  "Chains",
  "Couplings",
  // Building & Construction Supplies
  "Cement",
  "Bricks",
  "Steel",
  "Pipes",
  // Cleaning Equipment
  "Vacuum Cleaners",
  "Floor Scrubbers",
  "Pressure Washers",
  "Mops",
  "Brooms",
  // Electricals and Electronics
  "Wires & Cables",
  "Sockets",
  "Switches",
  "Circuit Breakers",
  "Lighting",
  //Fabrication_Customized_Products
  "Metal Fabrication", "Custom Furniture", "Welding Services", "Sheet Metal Work", "Customized Industrial Solutions",
//Fasteners
  "Bolts", "Nuts", "Screws", "Washers", "Anchors",
//Hardware_Cutting_Hand_Tools
  "Hammers", "Pliers", "Cutting Tools", "Saws", "Wrenches",
//Hydraulics_Pneumatics
  "Hydraulic Cylinders", "Hydraulic Pumps", "Pneumatic Valves", "Air Compressors", "Hoses",
//IT_Supplies
  "Laptops", "Monitors", "Keyboards", "Printers", "Networking Devices",
//Lab_Supplies
"Glassware", "Chemicals", "Instruments", "Protective Gear", "Measuring Devices",
//Luminaires_LEDs_Lights
"LED Bulbs", "Tube Lights", "Panel Lights", "Street Lights", "Flood Lights",
//Material_Handling_Packaging
"Conveyor Belts", "Pallets", "Trolleys", "Stretch Wrap", "Packing Tape",
//Mild_Steel
"Mild Steel Plates", "Mild Steel Pipes", "Mild Steel Angles", "Mild Steel Rods", "Mild Steel Sheets",
//Motors_and_Pumps
"Induction Motors", "Submersible Pumps", "Centrifugal Pumps", "Gear Motors", "Booster Pumps",
//Non_Ferrous_Products
"Aluminium Sheets", "Copper Rods", "Brass Tubes", "Zinc Ingots", "Lead Plates",
//Office_Stationery_Admin_Supplies
"Pens", "Notebooks", "Paper Clips", "Folders", "Staplers",
//Paints_Powders_Coatings
"Acrylic Paints", "Powder Coatings", "Epoxy Paints", "Polyurethane Paints", "Primer Coatings",
//Plates_Sheets_Coils_Rounds
"Steel Plates", "Aluminium Sheets", "Copper Coils", "Stainless Steel Rounds", "Brass Sheets",
//Plumbing_Pipes_Fittings
"PVC Pipes", "Copper Fittings", "Steel Pipes", "Brass Valves", "Plastic Fittings",
//Power_Tools
"Drills", "Angle Grinders", "Saws", "Impact Wrenches", "Sanders",
//Railway_Spares_Equipment
"Brake Blocks", "Rail Clips", "Fish Plates", "Railway Axles", "Track Bolts",
//Roofing_Sheets
"Corrugated Sheets", "Polycarbonate Sheets", "Metal Roofing Sheets", "Asphalt Sheets", "Fiber Cement Sheets",
//Safety_PPE_Supplies
"Safety Helmets", "Gloves", "Safety Glasses", "Hearing Protection", "Respirators",
//Security_Equipment
"CCTV Cameras", "Access Control Systems", "Alarm Systems", "Security Lights", "Metal Detectors",
//Solar_Products
"Solar Panels", "Solar Inverters", "Solar Batteries", "Solar Charge Controllers", "Solar Lights",
//Stainless_Steel_Products
"Stainless Steel Sheets", "Stainless Steel Pipes", "Stainless Steel Rods", "Stainless Steel Plates", "Stainless Steel Coils",
//Testing_Measuring_Instruments
"Multimeters", "Calipers", "Pressure Gauges", "Thermometers", "Sound Level Meters",
//Tooling_Cutting
"Drill Bits", "Cutting Discs", "End Mills", "Taps & Dies", "Reamers",
//Welding_Accessories
"Welding Machines", "Electrodes", "Welding Helmets", "Welding Cables", "Welding Rods",
//Wire_Meshes_Perforated_Sheets
"Welded Wire Mesh", "Chain Link Fencing", "Expanded Metal Mesh", "Perforated Sheets", "Woven Wire Mesh",
];

const ShopByCategoriProducts2 = ({ ShopByCategoriProductsdata }) => {
  // console.log("ShopByCategoriProducts2", ShopByCategoriProductsdata);

  const location = useLocation();
  const { subCatName } = location?.state || {};
  console.log("subCatName>>", subCatName);

  let [getActiveRow, setActiveRow] = useState(true);
  const handelRow = () => {
    setActiveRow(!getActiveRow);
  };

  const [sortOption, setSortOption] = useState("order");
  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const sortProducts = (products) => {
    switch (sortOption) {
      case "popularity":
        return products.slice().sort((a, b) => b.popularity - a.popularity);
      case "price":
        return products.slice().sort((a, b) => a.price - b.price);
      case "price-desc":
        return products.slice().sort((a, b) => b.price - a.price);
      // case 'date':
      //     // Assuming you have a date property in your product objects
      //     return products.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
      default:
        return products; // Default sorting
    }
  };
  const [setSingleProductDetails, getSingleProductDetails] = useState();

  const [getPopUpShow, setPopUpShow] = useState(false);
  const onhide = () => {
    setPopUpShow(false);
  };

  const handelclick = (index) => {
    setPopUpShow(true);
    //console.log("poroductdata",ShopByCategoriProductsdata.product[index],'index=>',index)
    getSingleProductDetails(ShopByCategoriProductsdata?.product[index]);
  };

  let [getCategories, setCategories] = useState();

  // console.log("getCategories>>",getCategories[0])
  useEffect(() => {
    setCategories(ShopByCategoriProductsdata?.categories);
  }, [ShopByCategoriProductsdata]);
  const sortedProducts = sortProducts(ShopByCategoriProductsdata?.product);
  console.log("ShopByCategoriProductsdata>>", ShopByCategoriProductsdata.path);
  const [selectedCategory, setSelectedCategory] = useState();
  useEffect(() => {
    if (getCategories && getCategories.length > 0) {
      setSelectedCategory(getCategories[0]);
    }
  }, [getCategories]);
  console.log("selectedCategory>>", selectedCategory);
  const filterProductsByCategory = () => {
    const selectedCategoryName = selectedCategory?.category;
  
    // Check if the selected category exists in the categories array
    if (categories.includes(selectedCategoryName)) {
      return sortedProducts.filter((product) => product.category === selectedCategoryName);
    }
  
    // Default return if category doesn't match
    return sortedProducts;
  };
  const filteredProducts = filterProductsByCategory();
  console.log("filteredProducts>>", filteredProducts.length);

  return (
    <>
      <div className="section">
        <PopupModal
          show={getPopUpShow}
          onHide={onhide}
          setSingleProductDetails={setSingleProductDetails}
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="row align-items-center mb-4 pb-1">
                <div className="col-12">
                  <div className="product_header">
                    <div className="product_header_left">
                      <div className="custom_select">
                        <select
                          className="form-control form-control-sm"
                          onChange={handleSortChange}
                          value={sortOption}
                        >
                          <option value="order">Default sorting</option>
                          <option value="popularity">Sort by popularity</option>
                          {/* <option value="date">Sort by newness</option> */}
                          {/* <option value="price">Sort by price: low to high</option>
                                        <option value="price-desc">Sort by price: high to low</option> */}
                        </select>
                      </div>
                    </div>
                    <div className="product_header_right">
                      <div className="products_view">
                        <p
                          onClick={handelRow}
                          className={`${
                            getActiveRow
                              ? "shorting_icon grid active my-0"
                              : "shorting_icon grid  my-0"
                          }`}
                        >
                          <i className="ti-view-grid"></i>
                        </p>
                        <p
                          onClick={handelRow}
                          className={`${
                            getActiveRow
                              ? "shorting_icon list  my-0"
                              : "shorting_icon list active my-0"
                          }`}
                        >
                          <i className="ti-layout-list-thumb"></i>
                        </p>
                        {/* <Link onClick={handelRow()} className="shorting_icon grid active"><i className="ti-view-grid"></i></Link>
                                    <Link onClick={handelCol()} className="shorting_icon list "><i className="ti-layout-list-thumb"></i></Link> */}
                      </div>
                      <div className="custom_select">
                        <select className="form-control form-control-sm">
                          <option value="">Showing</option>
                          <option value="9">9</option>
                          <option value="12">12</option>
                          <option value="18">18</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  getActiveRow
                    ? "row shop_container grid"
                    : "row shop_container list"
                }`}
              >
                {filteredProducts?.map((items, i) => (
                  <>
                    <div className="col-md-4 col-6">
                      <div className="product">
                        <div className="product_img">
                          <a href="shop-product-detail.html">
                            <img src={items?.image} alt="product_img1" />
                          </a>
                          <div className="product_action_box">
                            <ul className="list_none pr_action_btn">
                              {/* <li className="add-to-cart"><a href="#nothing"><i className="icon-basket-loaded"></i> Get Quote</a></li> */}
                              <li>
                                <a
                                  href="shop-compare.html"
                                  className="popup-ajax"
                                >
                                  <i className="icon-shuffle"></i>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="shop-quick-view.html"
                                  className="popup-ajax"
                                >
                                  <i className="icon-magnifier-add"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#nothing">
                                  <i className="icon-heart"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="product_info">
                          <h6 className="product_title d-flex align-items-center">
                            <sapn>
                              {items?.metrialName}
                            </sapn>
                          </h6>
                           
                          <div className="product_action_box">
                         
                            <Link className="d-flex">
                              <p
                                onClick={() => handelclick(i)}
                                className="getQouteproduct"
                              >
                                Get Quote
                              </p>
                              <p className="getQouteproduct w-25 ">
                             +
                            </p>
                            </Link>
                          </div>
                          <div className="product_price">
                            {/* <span className="price">${items.price}</span> */}
                            {/* <del>$55.25</del> */}
                            <div className="on_sale">
                              {/* <span>35% Off</span> */}
                            </div>
                          </div>
                          {/* <div className="rating_wrap">
                                    <div className="rating">
                                        <div className="product_rate" style={{width:"80%"}}></div>
                                    </div>
                                    <span className="rating_num">(21)</span>
                                </div> */}
                          <div className="pr_desc">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Phasellus blandit massa enim. Nullam id
                              varius nunc id varius nunc.
                            </p>
                          </div>
                          {/*  <div className="pr_switch_wrap">
                                    <div className="product_color_switch">
                                        <span className="active" data-color="#87554B"></span>
                                        <span data-color="#333333"></span>
                                        <span data-color="#DA323F"></span>
                                    </div>
                                </div> */}
                          <div className="list_product_action_box mt-5">
                            <ul className="list_none pr_action_btn d-flex">
                              <li
                                className="add-to-cart"
                                
                              >
                                  <p
                                onClick={() => handelclick(i)}
                                className="getQouteproduct m-0 text-center"
                              >
                                Get Quote
                              </p>
                              <p className="getQouteproduct w-25 m-0 mt-2 text-center">
                             +
                            </p>
                                {/* <sapn onClick={() => handelclick(i)}>
                                  <i className="icon-basket-loaded"></i> Get
                                  Quote
                                </sapn> */}
                              </li>
                              <li>
                                <a
                                  href="shop-compare.html"
                                  className="popup-ajax"
                                >
                                  <i className="icon-shuffle"></i>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="shop-quick-view.html"
                                  className="popup-ajax"
                                >
                                  <i className="icon-magnifier-add"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#nothing">
                                  <i className="icon-heart"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>

              {/* pagination */}
              <div className="row">
                <div className="col-12">
                  <ul className="pagination mt-3 justify-content-center pagination_style1">
                    <li className="page-item active">
                      <a className="page-link" href="#nothing">
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#nothing">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#nothing">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#nothing">
                        <i className="linearicons-arrow-right"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* categories */}
            <div className="col-lg-3 order-lg-first mt-4 pt-2 mt-lg-0 pt-lg-0">
              <div className="sidebar">
                <div className="widget">
                  <h5 className="widget_title">Categories</h5>
                  {/* ========================================Categories editing ======================================================= */}
                  <ul className="widget_categor1  ies">
                    {getCategories?.map((category) => (
                      <li>
                        <a href="#nothing">
                          <span
                            className="categories_name"
                            onClick={() => setSelectedCategory({ category })}
                          >
                            {/* Agriculture Garden{" "} */}
                            {category}
                          </span>
                          {/* <span className="categories_num">(9)</span> */}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                {/*   <div className="widget">
                    	<h5 className="widget_title">Filter</h5>
                        <div className="filter_price">
                             <div id="price_filter" data-min="0" data-max="500" data-min-value="50" data-max-value="300" data-price-sign="$"></div>
                             <div className="price_range">
                                 <span>Price: <span id="flt_price"></span></span>
                                 <input type="hidden" id="price_first"/>
                                 <input type="hidden" id="price_second"/>
                             </div>
                         </div>
                    </div> */}
                <div className="widget">
                  <h5 className="widget_title">Brand</h5>
                  <ul className="list_brand">
                    <li>
                      <div className="custome-checkbox">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checkbox"
                          id="Arrivals"
                          value=""
                        />
                        <label className="form-check-label" for="Arrivals">
                          <span>New Arrivals</span>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="custome-checkbox">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checkbox"
                          id="Lighting"
                          value=""
                        />
                        <label className="form-check-label" for="Lighting">
                          <span>Lighting</span>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="custome-checkbox">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checkbox"
                          id="Tables"
                          value=""
                        />
                        <label className="form-check-label" for="Tables">
                          <span>Tables</span>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="custome-checkbox">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checkbox"
                          id="Chairs"
                          value=""
                        />
                        <label className="form-check-label" for="Chairs">
                          <span>Chairs</span>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="custome-checkbox">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checkbox"
                          id="Accessories"
                          value=""
                        />
                        <label className="form-check-label" for="Accessories">
                          <span>Accessories</span>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
                {/*  <div className="widget">
                    	<h5 className="widget_title">Size</h5>
                        <div className="product_size_switch">
                            <span>xs</span>
                            <span>s</span>
                            <span>m</span>
                            <span>l</span>
                            <span>xl</span>
                            <span>2xl</span>
                            <span>3xl</span>
                        </div>
                    </div> */}
                {/* <div className="widget">
                    	<h5 className="widget_title">Color</h5>
                        <div className="product_color_switch">
                            <span data-color="#87554B"></span>
                            <span data-color="#333333"></span>
                            <span data-color="#DA323F"></span>
                            <span data-color="#2F366C"></span>
                            <span data-color="#B5B6BB"></span>
                            <span data-color="#B9C2DF"></span>
                            <span data-color="#5FB7D4"></span>
                            <span data-color="#2F366C"></span>
                        </div>
                    </div> */}
                {/*    <div className="widget">
                        <div className="shop_banner">
                            <div className="banner_img overlay_bg_20">
                                <img src="assets/images/sidebar_banner_img.jpg" alt="sidebar_banner_img"/>
                            </div> 
                            <div className="shop_bn_content2 text_white">
                                <h5 className="text-uppercase shop_subtitle">New Collection</h5>
                                <h3 className="text-uppercase shop_title">Sale 30% Off</h3>
                                <a href="#nothing" className="btn btn-white rounded-0 btn-sm text-uppercase">Shop Now</a>
                            </div>
                        </div>
                    </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ShopByCategoriProducts2;
