let whyByizzyData = [
  {
    title: "Efficient sourcing",
    image:"https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FWhy_Byizzy%2Fefficient%20sourcing.png?alt=media&token=9e29ca2a-60cc-4058-8332-01e366d2cee0",
    backTitle:"Efficient sourcing ensures fast, cost-effective procurement by connecting you with the right suppliers for optimal value and quality",
  },
  {
    title: "Competitive pricing",
    image:"https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FWhy_Byizzy%2Fcompetitive.png?alt=media&token=3db9a099-f67c-4db2-8ce1-eb1a7919a160",
    backTitle:"Competitive pricing guarantees the best value for your procurement needs, ensuring cost savings without compromising on quality",
  },
  {
    // title: "Multiple SKU’s from single supplier",
    title: "Multiple SKU’s from  supplier",
    image:"https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FWhy_Byizzy%2FMultiple_sku.png?alt=media&token=e3fff7a4-c341-493d-9114-788e1d3ff141",
    backTitle:"We received multiple SKUs from the supplier. Each SKU corresponds to a different product in their inventory",
  },
  {
    title: "Access to Credit",
    image:"https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FWhy_Byizzy%2FAccess%20to%20Credit.png?alt=media&token=baf48a80-75d2-41f7-a87a-5308a2f4ccdf",
    backTitle:
      "Access to credit is crucial for managing cash flow and financing growth. It allows businesses to make necessary investments and handle unexpected expenses efficiently",
  },
  {
    title: "On-time Delivery",
    image:"https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FWhy_Byizzy%2FOn-time%20delivery%20icon.png?alt=media&token=f8609515-958b-4966-bd1b-579ffaffa521",
    backTitle:"On-time delivery is key to customer satisfaction and trust. It ensures products arrive as expected, minimizing disruptions.",
  },
  {
    title: "Quality Assurance",
    image:"https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FWhy_Byizzy%2Fassurance.png?alt=media&token=405bddc7-91b2-4553-a49c-9b35f4e8de7d",
    backTitle:"Quality assurance ensures products meet required standards and specifications. It helps maintain consistency and reliability, enhancing customer satisfaction.",
  },
  {
    title: "Technical Knowledge & expertise",
    image:"https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FWhy_Byizzy%2FTechnical%20Knowledge%20%26%20Expertise.png?alt=media&token=3386d83a-bd4b-497e-911a-620da736d214",
    backTitle:"Technical knowledge and expertise are vital for addressing complex issues and implementing advanced solutions. They drive effective system management and innovation",
  },
  {
    title: "Social Obligations",
    image:"https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FWhy_Byizzy%2FSocial%20Obligations.png?alt=media&token=a2fefc1a-8a76-4e1b-a120-ac2cba81098d",
    backTitle:"Technical knowledge and expertise are essential for solving complex problems and driving innovation. They ensure effective system implementation and maintenance",
  },
  {
    title: "ESG compliance",
    image:"https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FWhy_Byizzy%2FESG%20compliance.png?alt=media&token=ed33c058-2f6c-4a1c-bddf-7c4e2968edc2",
    backTitle:"ESG compliance ensures that a company meets environmental, social, and governance standards. It helps build sustainability and ethical practices into business operations",
  },
];

const Section5 = () => {
  return (
    <>
    <div className="container small_pt pb_20">

      <div className="row justify-content-center my-5">
        <div className="col-lg-6 col-md-8 ">
          <div className="heading_s1 text-center">
            <h2>Why Byizzy?</h2>
          </div>
          <p className="text-center leads">
            Sourcing Success, Delivering Values
          </p>
        </div>
      </div>
    </div>
      <div className="container d-flex justify-content-center">
        <div className="row d-flex justify-content-center">
          {whyByizzyData.map((item, i) => (
            <div key={i} className=" col-lg-4 cards ">
              <div className="card-inner">
                <div className="card-front">
                  {/* <i className="ti-settings"></i> */}
                  <img className="img-fluid w-50" src={item.image} alt="altimg" />
                  <p>{item.title}</p>
                </div>
                <div className="card-back text-center">
                  <p>{item.backTitle}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default Section5;
