// import { Link } from "react-router-dom";
// import Section3LargeImage from "./Section3LargeImage";
// import Section3mediumImage from "./Section3mediumImage";
// import { Col, Row } from "react-bootstrap";

import { Link } from "react-router-dom";
// let shopByCategorieLarge = [
//   {
//     id: 1,
//     img: "./assets/images/SteelNew.webp",
//     title: "steel",
//     path: ">",
//   },
//   {
//     id: 1,
//     img: "./assets/images/SanitarywareNew.webp",
//     title: "sanitayhware & Bathfittings",
//     path: ">",
//   },
// ];
// let shopByCategoriemiddle = [
//   {
//     id: 1,
//     img: "./assets/images/Cement_Allied.webp",
//     title: "Cement & Allied",
//     path: "",
//   },
//   {
//     id: 1,
//     img: "./assets/images/Plumbing_Pipes.webp",
//     title: "Doors & Windows",
//     path: "",
//   },
//   {
//     id: 1,
//     img: "./assets/images/Plumbing_Pipes.webp",
//     title: "Tiles & Surfaces",
//     path: "",
//   },
//   {
//     id: 1,
//     img: "./assets/images/Cement_Allied.webp",
//     title: "Plumbing Pipe & Fittings",
//     path: "",
//   },
// ];

// let ShopByCategoriProductsdata=[
//     {
//         id:'1',
//         title:'Steel',
//         images:'assets/images/Plumbing_Pipes.webp',
//         path:'/Steel',
//     },
//     {
//         id:'2',
//         title:'Cement & Allid',
//         images:'assets/images/Cement_Allied.webp',
//         path:'/Cement',
//     },
//     {
//         id:'3',
//         title:'Tiles & Surface',
//         images:'assets/images/Tiles_and_Surfaces.webp',
//         path:'/tiles',
//     },
//     {
//         id:'4',
//         title:'Door & Windows',
//         images:'assets/images/doorsWindows.webp',
//         path:'/door',
//     },
//     {
//         id:'5',
//         title:'Plumbing Pipe & Fitting',
//         images:'assets/images/Plumbing_Pipes.webp',
//         path:'/plumbing',
//     },
//     {
//         id:'6',
//         title:'SanityahWare & Bathfittings',
//         images:'assets/images/doorsWindows.webp',
//         path:'/sanityahware',
//     },
// ]

const Section3 = () => {
  return (
    <>
      <div className="section small_pt pb_20">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="heading_tab_header">
                <div className="heading_s2">
                  <h2>Shop By Categories</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div
                className="product_slider product_list carousel_slider owl-carousel owl-theme nav_style3"
                data-loop="true"
                data-dots="false"
                data-nav="true"
                data-margin="20"
                data-responsive='{"0":{"items": "1"}, "767":{"items": "2"}, "991":{"items": "3"}, "1199":{"items": "4"}}'
              >
                <div className="item">
                  <Link to="/Abrasives_Adhesives_and_Sealants">
                    <div className="product">
                      <div className="product_img">
                        <span>
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FAbrasivesc.png?alt=media&token=9925e0b1-e3bd-4968-9016-684f7c48305e"
                            alt="product_img1"
                          />
                        </span>
                      </div>
                      <div className="product_info">
                        <h6 className="commmon-font">
                          Abrasives, Adhesives & Sealants
                        </h6>
                        {/* <div className="product_price">
                        <span className="price">$45.00</span>
                        <del>$55.25</del>
                        <div className="on_sale">
                          <span>35% Off</span>
                        </div>
                      </div> */}
                        {/* <div className="rating_wrap"> */}
                        {/* <div className="rating">
                          <div
                            className="product_rate"
                            style={{ width: "80%" }}
                          ></div>
                        </div> */}
                        {/* <span className="rating_num">(21)</span> */}
                        {/* </div> */}
                      </div>
                    </div>
                  </Link>
                  <Link to="/Agriculture_Garden_Landscaping">
                    <div className="product">
                      <div className="product_img">
                        <Link href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FAgriculture.png?alt=media&token=ed324fda-ae0c-4ba2-b892-9320286b01b9"
                            alt="product_img6"
                          />
                        </Link>
                      </div>
                      <div className="product_info">
                        <h6 className="commmon-font">
                          Agriculture, Garden & Landscaping
                        </h6>
                        {/* <div className="product_price">
                        <span className="price">$55.00</span>
                        <del>$95.00</del>
                        <div className="on_sale">
                          <span>25% Off</span>
                        </div>
                      </div>
                      <div className="rating_wrap">
                        <div className="rating">
                          <div
                            className="product_rate"
                            style={{ width: "68%" }}
                          ></div>
                        </div>
                        <span className="rating_num">(15)</span>
                      </div> */}
                      </div>
                    </div>
                  </Link>
                  <Link to="/Automotive_Supplies_and_Lubricants">
                    <div className="product">
                      <div className="product_img">
                        <span>
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FAutomative.png?alt=media&token=896fab3f-ca6b-44a4-9393-dc8a3e8e311c"
                            alt="product_img12"
                          />
                        </span>
                      </div>
                      <div className="product_info">
                        <h6 className="commmon-font">
                          Automative Supplies & Lubricants
                        </h6>
                        {/* <div className="product_price">
                        <span className="price">$69.00</span>
                        <del>$89.00</del>
                        <div className="on_sale">
                          <span>20% Off</span>
                        </div>
                      </div>
                      <div className="rating_wrap">
                        <div className="rating">
                          <div
                            className="product_rate"
                            style={{ width: "70%" }}
                          ></div>
                        </div>
                        <span className="rating_num">(22)</span>
                      </div> */}
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="item">
                  <Link to="/Bearings_and_Power_Transmission">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FBearings.png?alt=media&token=2b8cf9c1-78d7-4559-933a-8315cd2a4d54"
                            alt="product_img2"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="commmon-font">
                          Bearings & Power Transmission
                        </h6>
                        {/* <div className="product_price">
                        <span className="price">$55.00</span>
                        <del>$95.00</del>
                        <div className="on_sale">
                          <span>25% Off</span>
                        </div>
                      </div>
                      <div className="rating_wrap">
                        <div className="rating">
                          <div
                            className="product_rate"
                            style={{ width: "68%" }}
                          ></div>
                        </div>
                        <span className="rating_num">(15)</span>
                      </div> */}
                      </div>
                    </div>
                  </Link>
                  <Link to="/Building_and_Construction_Supplies">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FBuilding.png?alt=media&token=cc2b1c5b-3fc6-475b-90f2-94b0aa18f0b6"
                            alt="product_img7"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="commmon-font">
                          Building & construction supplies
                        </h6>
                        {/* <div className="product_price">
                        <span className="price">$68.00</span>
                        <del>$99.00</del>
                        <div className="on_sale">
                          <span>20% Off</span>
                        </div>
                      </div>
                      <div className="rating_wrap">
                        <div className="rating">
                          <div
                            className="product_rate"
                            style={{ width: "87%" }}
                          ></div>
                        </div>
                        <span className="rating_num">(25)</span>
                      </div> */}
                      </div>
                    </div>
                  </Link>
                  <Link to="/Cleaning_Equipment">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FCleaning.png?alt=media&token=ca05bb98-9398-4f84-9b84-336d6d7aa15f"
                            alt="product_img10"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">Cleaning Equipments</h6>
                        {/* <div className="product_price">
                        <span className="price">$55.00</span>
                        <del>$95.00</del>
                        <div className="on_sale">
                          <span>25% Off</span>
                        </div>
                      </div>
                      <div className="rating_wrap">
                        <div className="rating">
                          <div
                            className="product_rate"
                            style={{ width: "68%" }}
                          ></div>
                        </div>
                        <span className="rating_num">(15)</span>
                      </div> */}
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="item">
                  <Link to="/Electricals_and_Electronics">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FElectricals.png?alt=media&token=8cbe235f-b135-406c-98b2-e57c94a773d0"
                            alt="product_img3"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">
                          Electricals & Electronics
                        </h6>
                        {/* <div className="product_price">
                        <span className="price">$68.00</span>
                        <del>$99.00</del>
                      </div>
                      <div className="rating_wrap">
                        <div className="rating">
                          <div
                            className="product_rate"
                            style={{ width: "87%" }}
                          ></div>
                        </div>
                        <span className="rating_num">(25)</span>
                      </div> */}
                      </div>
                    </div>
                  </Link>
                  <Link to="/Fabrication_Customized_Products">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FFabrication.png?alt=media&token=99131e98-21ed-450b-9b0b-ebaeed34ab97"
                            alt="product_img8"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">
                          Fabrication & customized products
                        </h6>
                        {/* <div className="product_price">
                        <span className="price">$69.00</span>
                        <del>$89.00</del>
                        <div className="on_sale">
                          <span>20% Off</span>
                        </div>
                      </div>
                      <div className="rating_wrap">
                        <div className="rating">
                          <div
                            className="product_rate"
                            style={{ width: "70%" }}
                          ></div>
                        </div>
                        <span className="rating_num">(22)</span>
                      </div> */}
                      </div>
                    </div>
                  </Link>
                  <Link to="/Fasteners">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FFasteners.png?alt=media&token=b014d08c-ebd1-47c5-8797-cccd045b827d"
                            alt="product_img13"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="product_title">
                          <a href="shop-product-detail.html">Fasteners</a>
                        </h6>
                        {/* <div className="product_price">
                        <span className="price">$65.00</span>
                        <del>$80.00</del>
                        <div className="on_sale">
                          <span>30% Off</span>
                        </div>
                      </div>
                      <div className="rating_wrap">
                        <div className="rating">
                          <div
                            className="product_rate"
                            style={{ width: "80%" }}
                          ></div>
                        </div>
                        <span className="rating_num">(28)</span>
                      </div> */}
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="item">
                  <Link to="">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FFurniture.png?alt=media&token=8051ef9e-c04c-4bf0-be76-6bbd5202a76a"
                            alt="product_img4"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="product_title">
                          <a href="shop-product-detail.html">
                            Furniture, Hospitality & Food Services
                          </a>
                        </h6>
                        {/* <div className="product_price">
                        <span className="price">$69.00</span>
                        <del>$89.00</del>
                        <div className="on_sale">
                          <span>20% Off</span>
                        </div>
                      </div>
                      <div className="rating_wrap">
                        <div className="rating">
                          <div
                            className="product_rate"
                            style={{ width: "70%" }}
                          ></div>
                        </div>
                        <span className="rating_num">(22)</span>
                      </div> */}
                      </div>
                    </div>
                  </Link>
                  <Link to="/Hardware_Cutting_Hand_Tools">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FHardware.png?alt=media&token=8a514928-addd-4cf6-8644-7cb0b7baf685"
                            alt="product_img9"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">
                          Hardware, Cutting and Hand Tools
                        </h6>
                        {/* <div className="product_price">
                        <span className="price">$45.00</span>
                        <del>$55.25</del>
                        <div className="on_sale">
                          <span>35% Off</span>
                        </div>
                      </div>
                      <div className="rating_wrap">
                        <div className="rating">
                          <div
                            className="product_rate"
                            style={{ width: "80%" }}
                          ></div>
                        </div>
                        <span className="rating_num">(21)</span>
                      </div> */}
                      </div>
                    </div>
                  </Link>
                  <Link to="/Hydraulics_Pneumatics">
                    <div className="product">
                      <div className="commont-font">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FHydraulics.png?alt=media&token=5504d9c4-cba8-42c2-9dd3-72f32ec43767"
                          alt="product_img14"
                        />
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">Hydraulics & Pneumatics</h6>
                        {/* <div className="product_price">
                        <span className="price">$55.00</span>
                        <del>$60.00</del>
                      </div>
                      <div className="rating_wrap">
                        <div className="rating">
                          <div
                            className="product_rate"
                            style={{ width: "68%" }}
                          ></div>
                        </div>
                        <span className="rating_num">(15)</span>
                      </div> */}
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="item">
                  <Link to="/IT_Supplies">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FITSupplies.png?alt=media&token=37d6cd1f-304f-4054-a2a6-c846c3708cab"
                            alt="product_img5"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">ITSupplies</h6>
                      </div>
                    </div>
                  </Link>
                  <Link to="/Lab_Supplies">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2Flab.png?alt=media&token=161c533d-26d9-4cc0-9e14-8c2995966348"
                            alt="product_img11"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">Lab supplies</h6>
                      </div>
                    </div>
                  </Link>
                  <Link to="/Luminaires_LEDs_Lights">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2Fled.png?alt=media&token=e2b9293a-38f5-49ce-9d55-32725f06b9e7"
                            alt="product_img15"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">
                          Luminaires, LEDs & Lights
                        </h6>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="item">
                  <Link to="/Material_Handling_Packaging">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FPackaging.png?alt=media&token=d52af5c2-2df2-408d-89f9-96c65c9ea2d2"
                            alt="product_img5"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">
                          Material Handling & Packaging
                        </h6>
                      </div>
                    </div>
                  </Link>
                  <Link to="/Mild_Steel">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FMildSteel.png?alt=media&token=6f4bae50-bdc6-41b5-97a3-d4d76daa957b"
                            alt="product_img11"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">Mild Steel</h6>
                      </div>
                    </div>
                  </Link>
                  <Link to="/Motors_and_Pumps">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FMotors.png?alt=media&token=3fc11b41-edcc-4add-9cf5-3ef5e1ab3481"
                            alt="product_img15"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">Motors & Pumps</h6>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="item">
                  <Link to="/Non_Ferrous_Products">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FNonFerrousProducts.png?alt=media&token=06eccb48-4d87-4f89-8893-02318113e8b2"
                            alt="product_img11"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">Non Ferrous Products</h6>
                      </div>
                    </div>
                  </Link>
                  <Link to="/Office_Stationery_Admin_Supplies">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FOfficeStationery.png?alt=media&token=fe85a2d2-01e6-4c4d-a942-577a15441968"
                            alt="product_img15"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">
                          Office Stationery & admin supplies
                        </h6>
                      </div>
                    </div>
                  </Link>
                  <Link to="/Paints_Powders_Coatings">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FPaints.png?alt=media&token=3f5025fa-3a07-4ebe-80e1-df7ebe63d970"
                            alt="product_img5"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">
                          Paints, Powders & Coatings
                        </h6>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="item">
                  <Link to="/Plates_Sheets_Coils_Rounds">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FPlates.png?alt=media&token=438b9164-ff7b-4001-8902-5bde091f712d"
                            alt="product_img11"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">
                          Plates, Sheets, Coils & Rounds
                        </h6>
                      </div>
                    </div>
                  </Link>
                  <Link to="/Plumbing_Pipes_Fittings">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FPlumbing.png?alt=media&token=6918b001-a061-491a-97b8-fbfd9b58c172"
                            alt="product_img15"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">
                          Plumbing, Pipes & Fittings
                        </h6>
                      </div>
                    </div>
                  </Link>
                  <Link to="/Power_Tools">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FPowerTools.png?alt=media&token=7d675026-1ac0-401d-b23a-1b75acdb107b"
                            alt="product_img5"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">Power Tools</h6>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="item">
                  <Link to="/Railway_Spares_Equipment">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FRailway.png?alt=media&token=a7ca7172-4d5a-426a-ba66-a193a89c55c8"
                            alt="product_img11"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">
                          Railway Spares & equipments
                        </h6>
                      </div>
                    </div>
                  </Link>
                  <Link to="/Roofing_Sheets">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FRoofing.png?alt=media&token=b8807994-81d7-4373-b8ea-46af66ca1d4a"
                            alt="product_img15"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">Roofing Sheets</h6>
                      </div>
                    </div>
                  </Link>
                  <Link className="Safety_PPE_Supplies">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FSafety.png?alt=media&token=ae79946e-ab21-4413-ba1a-63decc9cbde6"
                            alt="product_img5"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">Safety & PPE supplies</h6>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="item">
                  <Link to="/Solar_Products">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FSolar.png?alt=media&token=abfdc750-fdbe-47b2-8740-4f9cdc797f79"
                            alt="product_img11"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">Solar Products</h6>
                      </div>
                    </div>
                  </Link>
                  <Link to="/Stainless_Steel_Products">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FStainless.png?alt=media&token=c8a5796f-98f9-4f94-a543-9f57b95c98fd"
                            alt="product_img15"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">
                          Stainless Steel Products
                        </h6>
                      </div>
                    </div>{" "}
                  </Link>
                  <Link to="/Testing_Measuring_Instruments">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FTesting.png?alt=media&token=4e5cab22-7f99-4cf9-8565-ec5fbb59cb53"
                            alt="product_img5"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">
                          Testing & Measuring Instruments
                        </h6>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="item">
                  <Link to="/Tooling_Cutting">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FTooling.png?alt=media&token=29491e42-e5d6-44f1-a631-5c34ce988dad"
                            alt="product_img5"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">Tooling & cutting</h6>
                      </div>
                    </div>{" "}
                  </Link>
                  <Link to="/Welding_Accessories">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2Fwelder.png?alt=media&token=02c8f65a-8ec0-4c38-b998-73df823dd149"
                            alt="product_img15"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">Welding & accessories</h6>
                      </div>
                    </div>
                  </Link>
                  <Link to="/Wire_Meshes_Perforated_Sheets">
                    <div className="product">
                      <div className="product_img">
                        <a href="shop-product-detail.html">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/byizzy-5aa57.appspot.com/o/website%2FShop%20By%20Categories%20main%20images%2FWire.png?alt=media&token=141d561e-1740-4246-9e88-ec1d117198ec"
                            alt="product_img5"
                          />
                        </a>
                      </div>
                      <div className="product_info">
                        <h6 className="common-font">
                          Wire Meshes & Perforated sheets
                        </h6>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Section3;
